import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/softwareDevelopmentSections/HeroBody';
import EllipseQuoteSection from '../../components/TopOrangeEllipseQuote';
import WhiteQuoteSection from '../../components/LightQuote';
import BestBlogs from '../../components/BestBlogs';
import FormSection from '../../components/Form';

const pageTitle =
  'Custom Software Development | Enterprise Software | Devetry ';
const pageDescription =
  'Find custom software development solutions by partnering with Devetry! We design, build, and manage enterprise custom software.';

const SoftwareDevelopmentPageTemplate = ({ blogs, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="salesforce-section root-container">
        <Hero
          title="Custom Software Development"
          description="Like choosing your own adventure, but with tech."
          image={heroImage}
          body={<HeroBody />}
        />
        <EllipseQuoteSection
          title="Agile Software Development Process"
          body={
            <span>
              <p className="soft-dev-quote">
                Our project managers are SCRUM certified and ready to jump into
                your process on day one. By leveraging continuous integration,
                continuous deployment (CI/CD), we can reduce risk, increase
                testing, and add incremental value.
                <br />
                <br />
                <strong>
                  Practice an Agile-but-not-really process? We’re flexible and
                  happy to conform.
                </strong>
              </p>
            </span>
          }
        />
        <WhiteQuoteSection
          title="Software Expertise. Industry Knowledge. Global Reach."
          body={
            <span>
              <p className="soft-dev-white-quote">
                Between Devetry’s in-house developers and DEPT®’s global
                technology network, we’ve worked in every language, every
                framework, and every process.
                <br />
                <br />
                That obscure language in your legacy system? We’ve done it. The
                newest framework from Google? We’ve learned it. And we’re happy
                to share our software development expertise with your team.
                <br />
                <br />
                Alongside our technology experience is our industry knowledge.
                We’ve worked deeply in energy sciences, healthcare, education,
                real estate, martech, fintech, biotechnology, and hospitality.
                We’ve built learning management systems, CRMs, websites,
                enterprise web and mobile apps, and more. We’d love to learn
                about your industry and tech needs!
                <br />
                <br />
                If you need help with your next custom software development
                project, let us help! Contact us below to meet with one of our
                team members.
              </p>
            </span>
          }
        />
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <FormSection />
      </main>
    </>
  );
};

const SoftwareDevelopmentPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const blogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const heroImage = data.file;

  return (
    <SoftwareDevelopmentPageTemplate blogs={blogs} heroImage={heroImage} />
  );
};

SoftwareDevelopmentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default SoftwareDevelopmentPage;

export const pageQuery = graphql`
  query SoftwareDevelopmentPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "SoftwareDevelopment/index" } }
    ) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true }
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: { eq: "code-on-computer5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
