import React from 'react';

import './index.css';

const HeroBody = () => {
  return (
    <section className="software-development-hero-body-container">
      <p>
        In today’s world, you need software to conduct business. However, you
        might not have the in-house talent or budget to build it yourself. Enter
        Devetry. Custom software development is our bread and butter.
        <br />
        <br />
        There are many ways to develop custom software development, but not all
        of them are right for your needs. Onshore, offshore, nearshore,
        contractor, in-house and hybrid options are valid, but it depends on
        your circumstances. How can you tell which one is right for your
        project?
        <br />
        <br />
        At Devetry, our mission is to help businesses navigate the complex world
        of building software with open conversations, industry analysis, and a
        focus on ROI. If we’re a good fit for your business, we’d love to build
        your custom software solution so you can focus on your core
        competencies.
        <br />
        <br />
        Whether you’re an enterprise looking for help with internal efficiencies
        or a digital product company looking to leverage a new technology, we’ve
        got you covered! Our global team has decades of experience working with
        clients ranging from startups to Fortune 500 companies and everyone in
        between.
      </p>
    </section>
  );
};

export default HeroBody;
